import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Button } from '@frontend/components/Button';
import { useAccountContext } from '@frontend/providers/AccountProvider';
import { useWeb3React } from '@web3-react/core';
import * as React from 'react';
import { ConnectedInfo } from './components/ConnectedInfo';
export var ConnectWallet = function ConnectWallet(_ref) {
  var hideArrow = _ref.hideArrow;

  var _useAccountContext = useAccountContext(),
      onConnect = _useAccountContext.onConnect,
      connectorId = _useAccountContext.connectorId;

  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account,
      active = _useWeb3React.active,
      error = _useWeb3React.error;

  if (error) {// return <FullWidthButton onClick={onConnect}>Connect Wallet</FullWidthButton>
  } else if (active) {
    return _jsx(ConnectedInfo, {
      account: account,
      connectorId: connectorId,
      hideArrow: hideArrow
    });
  } // else if (status === 'connecting' || reloading) {
  //   return <StyledCircleSpinner />
  // }


  return _jsx(Button, {
    onClick: onConnect,
    style: {
      width: '100%',
      whiteSpace: 'nowrap'
    },
    children: "Connect Wallet"
  });
};
export default ConnectWallet;